import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { combineLatest, map, shareReplay, switchMap } from 'rxjs';

import type { StatusInfo } from '@bo/common';
import { autoWithdrawalToStatusInfo, PlayerService } from '@bo/common';

import { StatusService } from './status.service';
import { StatusesProviderService } from './statuses.provider.service';

function intersectionCountToMultiAccount(count: string): StatusInfo {
  return {
    label: `MA ${count}`,
    className: 'warning-status',
  };
}

function isOneClickToStatusInfo(isOneClick: boolean): StatusInfo {
  return {
    label: `1 Click ${isOneClick ? 'ON' : 'OFF'}`,
    className: null,
  };
}

@Injectable()
export class StatusManagementService {
  private readonly provider = inject(StatusesProviderService);
  private readonly statusService = inject(StatusService);

  private readonly playerId$ = inject(PlayerService).playerId$;
  private readonly player$ = inject(PlayerService).player$;

  private readonly statuses$ = this.player$.pipe(
    map(({ status }) => this.statusService.getStatuses(status)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly autoWithdrawal$ = this.player$.pipe(
    map((player) => player.autoWithdrawal),
    map(autoWithdrawalToStatusInfo),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly isProbablyGood$: Observable<boolean> = this.player$.pipe(
    map(({ isProbablyGood }) => isProbablyGood),
    shareReplay(1),
  );

  public readonly multiAccount$ = this.playerId$.pipe(
    switchMap((playerId) => this.provider.getPlayerIntersections({ playerId })),
    map(intersectionCountToMultiAccount),
    shareReplay(1),
  );

  public readonly isOneClickedEnabled$ = this.playerId$.pipe(
    switchMap((playerId) => this.provider.getIsOneClickEnabled({ playerId })),
    map(isOneClickToStatusInfo),
    shareReplay(1),
  );

  public readonly allStatuses$ = combineLatest([
    this.statuses$,
    this.isOneClickedEnabled$,
    this.autoWithdrawal$,
  ]).pipe(
    map(([statuses, isOneClickedEnabled, autoWithdrawal]) => [
      ...statuses,
      isOneClickedEnabled,
      autoWithdrawal,
    ]),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
}
