import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { DEFAULT_PAGE_SIZE } from '../pagination.const';
import { PAGE_SELECT_ITEMS } from './constants';

@Component({
  selector: 'bo-page-size-select',
  templateUrl: './page-size-select.component.html',
  styleUrls: ['./page-size-select.component.scss'],
  standalone: true,
  imports: [NgSelectModule, FormsModule],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation -- FIXME
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSizeSelectComponent {
  @Input() public pageSelectItems = PAGE_SELECT_ITEMS;
  @Input() public pageSize = DEFAULT_PAGE_SIZE;

  @Output() public readonly pageSizeChange = new EventEmitter<number>();
}
