import {
  ChangeDetectionStrategy,
  Component,
  computed,
  model,
  output,
} from '@angular/core';

import { SvgComponent } from '@pu/sdk';
import {
  BASIC_TO_DATE_SORT_MAP,
  checkMarkIcon,
  chevronDownIcon,
  chevronUpIcon,
  SortName,
} from '@bo/common';

import { OutsideClickDirective } from '../../outside-click/outside-click.directive';

@Component({
  selector: 'bo-sort-by-date',
  standalone: true,
  imports: [SvgComponent, OutsideClickDirective],
  templateUrl: './sort-by-date.component.html',
  styleUrl: './sort-by-date.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByDateComponent {
  public readonly checkMarkIcon = checkMarkIcon;
  public readonly chevronUpIcon = chevronUpIcon;
  public readonly chevronDownIcon = chevronDownIcon;
  protected readonly SortName = SortName;
  public selectedOrder = model<SortName | null>(SortName.DESC);
  public isOpen = model(false);
  public isNewest = computed(() => this.selectedOrder() === SortName.DESC);
  public isOldest = computed(() => this.selectedOrder() === SortName.ASC);
  public isActive = computed(() => this.isOpen() && this.selectedOrder());
  public selectedDateOrder = computed(() =>
    BASIC_TO_DATE_SORT_MAP.get(this.selectedOrder()),
  );

  public readonly sort = output<SortName>();

  public setSortOrder(sortOder: SortName): void {
    this.selectedOrder.set(sortOder);
    this.sort.emit(sortOder);
  }

  public toggleDropdown(): void {
    this.isOpen.update((value) => !value);
  }

  public closeDropdown(): void {
    this.isOpen.set(false);
  }
}
