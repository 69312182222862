import { inject, Injectable } from '@angular/core';
import type {
  Player,
  Status,
} from '@pinup-grpc/services/bo/players/players_pb';
import { CertifiedState } from '@pinup-grpc/services/bo/players/players_pb';
import type { Observable } from 'rxjs';
import {
  combineLatest,
  map,
  shareReplay,
  startWith,
  Subject,
  switchMap,
} from 'rxjs';

import type { ExtractPBMessagePayload, StatusInfo } from '@bo/common';
import {
  accountVerifyStatusesMap,
  activityStatusesMap,
  autoWithdrawalToStatusInfo,
  bettingStatusesMap,
  casinoStatusesMap,
  certifiedStatusesMap,
  depositStatusesMap,
  digitainStatusesMap,
  getStatusFromMap,
  License,
  PlayerService,
  PlayersProvider,
  privilegeStatusesMap,
  StatusesProviderService,
} from '@bo/common';

export type PlayerVM = Omit<
  ExtractPBMessagePayload<Player>,
  'autoWithdrawal'
> & {
  systemStatuses: StatusInfo[];
  casinoStatuses?: StatusInfo;
  bettingStatuses?: StatusInfo;
  autoWithdrawal: StatusInfo;
  accountVerifyStatuses?: StatusInfo;
  awOff: boolean;
};

const certifiedStatuses = [
  CertifiedState.CERTIFIED_STATUS_CERTIFIED,
  CertifiedState.CERTIFIED_STATUS_CERTIFIED_MANUALLY,
];

export function mapSystemStatuses(statuses: Status): StatusInfo[] {
  const systemStatuses = [
    getStatusFromMap(digitainStatusesMap, statuses.digitainStatusId),
    getStatusFromMap(activityStatusesMap, statuses.activity),
    getStatusFromMap(depositStatusesMap, statuses.deposit),
    getStatusFromMap(privilegeStatusesMap, statuses.privilege),
  ];

  if (certifiedStatuses.includes(statuses.certified.state)) {
    systemStatuses.push(
      getStatusFromMap(certifiedStatusesMap, statuses.certified.state),
    );
  }
  return systemStatuses;
}

export function constructVM(player: Player, license: License): PlayerVM {
  const playerVM: PlayerVM = {
    ...player,
    autoWithdrawal: autoWithdrawalToStatusInfo(player.autoWithdrawal),
    systemStatuses: mapSystemStatuses(player.status),
    accountVerifyStatuses: getStatusFromMap(
      accountVerifyStatusesMap,
      player.status.account.state,
    ),
    awOff: player.autoWithdrawal.off,
  };

  if (license.supports('sport')) {
    playerVM.bettingStatuses = getStatusFromMap(
      bettingStatusesMap,
      player.status.betting,
    );
  }

  if (license.supports('casino')) {
    playerVM.casinoStatuses = getStatusFromMap(
      casinoStatusesMap,
      player.status.casino,
    );
  }

  return playerVM;
}

// TODO: get rid of this service, move to player service
@Injectable()
export class ProfileViewService {
  private readonly playerProvider = inject(PlayersProvider);
  public readonly playerId$ = inject(PlayerService).playerId$;

  private readonly statusesProvider = inject(StatusesProviderService);
  private readonly refresh$ = new Subject<void>();

  private readonly license = inject(License);

  /*
   * TODO: Consider creating a custom operator that is more comfortable managing
   *  observables with triggers like refresh$ to avoid duplicating logic in
   *  different places and make the code more declarative.
   * */
  public readonly player$ = combineLatest([
    this.playerId$,
    this.refresh$.pipe(startWith(undefined)),
  ]).pipe(
    switchMap(([id]) => this.playerProvider.getPlayer(id)),
    map((player) => constructVM(player, this.license)),
    shareReplay(1),
  );

  public readonly verifiedPlayer$: Observable<boolean> = this.player$.pipe(
    map(({ status }) => status.account.verified),
  );

  public readonly vipPlayer$ = this.player$.pipe(map(({ isVip }) => isVip));

  public readonly multiAccount$ = this.playerId$.pipe(
    switchMap((playerId) =>
      this.statusesProvider.getPlayerIntersections({ playerId }),
    ),
    shareReplay(1),
  );

  public refreshPlayer(): void {
    this.refresh$.next();
  }
}
