import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timestamp } from '@bufbuild/protobuf';
import { BillingRemoteService } from '@pinup-grpc/pinup/stat/report/billing/billing_ng';
import type {
  ListTransactionsRequest,
  ListTransactionsRequest_Filter,
  ListTransactionsResponse,
} from '@pinup-grpc/pinup/stat/report/billing/billing_pb';
import { TimeRange } from '@pinup-grpc/pinup/std/query/v1/filter_pb';
import type { Player } from '@pinup-grpc/services/bo/players/players_pb';
import { merge } from 'lodash-es';
import type { Observable } from 'rxjs';

import type { Pagination } from '@bo/common';
import { DEFAULT_CONVERT_CURRENCY } from '@bo/common';

const ONE_DAY = BigInt(60 * 60 * 24 - 1);

@Injectable()
export class TransactionsProvider {
  private readonly billingRemoteService = inject(BillingRemoteService);
  private readonly snackBar = inject(MatSnackBar);

  public getTransactions(
    { currentPage: page, pageSize }: Pagination,
    player: Player | null,
    filter: Partial<ListTransactionsRequest_Filter>,
    period?: ListTransactionsRequest['period'],
  ): Observable<ListTransactionsResponse> {
    const defaultCurrency = player?.currency || DEFAULT_CONVERT_CURRENCY;

    const noPlayer = !player && !filter.playerIds?.length;
    if (noPlayer && period) {
      if (period.to.seconds - period.from.seconds > ONE_DAY) {
        period = new TimeRange({
          from: period.from,
          to: new Timestamp({ seconds: period.from.seconds + ONE_DAY }),
        });

        this.snackBar.open(
          'To view data for more than 24 hours, you need to specify a player ID. Displaying data for the last 24 hours',
          'OK',
          { duration: 5000 },
        );
      }
    }

    return this.billingRemoteService.listTransactions({
      convertCurrency: DEFAULT_CONVERT_CURRENCY as string,
      pagination: { page, pageSize },
      period,
      sorting: [],
      filter: {
        playerIds: player ? [player.id] : [],
        ...merge(filter, {
          amount: {
            currency: filter.amount?.currency || defaultCurrency,
          },
          balance: {
            currency: filter.balance?.currency || defaultCurrency,
          },
          balanceBefore: {
            currency: filter.balanceBefore?.currency || defaultCurrency,
          },
        }),
      },
    });
  }
}
