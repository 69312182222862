// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/backoffice/players.proto (package git.time2go.tech.apis.pinup.players.services.backoffice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetRestrictionsRequest, GetRestrictionsResponse, PartialUpdateFinanceSectionRequest, PartialUpdateFinanceSectionResponse, SetAccountReverificationRequest, SetAccountReverificationResponse, SetAccountVerifiedRequest, SetAccountVerifiedResponse, SetBettingStatusRequest, SetBettingStatusResponse, SetBlockedRequest, SetBlockedResponse, SetCasinoStatusRequest, SetCasinoStatusResponse, SetEmailRequest, SetEmailResponse, SetIsEmailVerifiedRequest, SetIsEmailVerifiedResponse, SetIsPhoneVerifiedRequest, SetIsPhoneVerifiedResponse, SetIsTestRequest, SetIsTestResponse, SetPhoneRequest, SetPhoneResponse, SetTwoFactorVerificationRequest, SetTwoFactorVerificationResponse, SetUnblockedRequest, SetUnblockedResponse, UpdateAutoWithdrawalStatusRequest, UpdateAutoWithdrawalStatusResponse, UpdateHackedRequest, UpdateHackedResponse, UpdateProbablyGoodRequest, UpdateProbablyGoodResponse, UpdateProjectRequest, UpdateProjectResponse, UpdateRestrictionsRequest, UpdateRestrictionsResponse } from "./players_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService
 */
@Injectable({ providedIn: "root" })
export class PlayerRemoteService {
  public static readonly typeName = "git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService";

  public static readonly methods = {
    setIsTest: {
      name: "SetIsTest",
      I: SetIsTestRequest,
      O: SetIsTestResponse,
      kind: MethodKind.Unary,
    },
    updateProbablyGood: {
      name: "UpdateProbablyGood",
      I: UpdateProbablyGoodRequest,
      O: UpdateProbablyGoodResponse,
      kind: MethodKind.Unary,
    },
    updateAutoWithdrawalStatus: {
      name: "UpdateAutoWithdrawalStatus",
      I: UpdateAutoWithdrawalStatusRequest,
      O: UpdateAutoWithdrawalStatusResponse,
      kind: MethodKind.Unary,
    },
    updateHacked: {
      name: "UpdateHacked",
      I: UpdateHackedRequest,
      O: UpdateHackedResponse,
      kind: MethodKind.Unary,
    },
    updateProject: {
      name: "UpdateProject",
      I: UpdateProjectRequest,
      O: UpdateProjectResponse,
      kind: MethodKind.Unary,
    },
    setBlocked: {
      name: "SetBlocked",
      I: SetBlockedRequest,
      O: SetBlockedResponse,
      kind: MethodKind.Unary,
    },
    setUnblocked: {
      name: "SetUnblocked",
      I: SetUnblockedRequest,
      O: SetUnblockedResponse,
      kind: MethodKind.Unary,
    },
    getRestrictions: {
      name: "GetRestrictions",
      I: GetRestrictionsRequest,
      O: GetRestrictionsResponse,
      kind: MethodKind.Unary,
    },
    updateRestrictions: {
      name: "UpdateRestrictions",
      I: UpdateRestrictionsRequest,
      O: UpdateRestrictionsResponse,
      kind: MethodKind.Unary,
    },
    partialUpdateFinanceSection: {
      name: "PartialUpdateFinanceSection",
      I: PartialUpdateFinanceSectionRequest,
      O: PartialUpdateFinanceSectionResponse,
      kind: MethodKind.Unary,
    },
    setAccountVerified: {
      name: "SetAccountVerified",
      I: SetAccountVerifiedRequest,
      O: SetAccountVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setAccountReverification: {
      name: "SetAccountReverification",
      I: SetAccountReverificationRequest,
      O: SetAccountReverificationResponse,
      kind: MethodKind.Unary,
    },
    setEmail: {
      name: "SetEmail",
      I: SetEmailRequest,
      O: SetEmailResponse,
      kind: MethodKind.Unary,
    },
    setIsEmailVerified: {
      name: "SetIsEmailVerified",
      I: SetIsEmailVerifiedRequest,
      O: SetIsEmailVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setPhone: {
      name: "SetPhone",
      I: SetPhoneRequest,
      O: SetPhoneResponse,
      kind: MethodKind.Unary,
    },
    setIsPhoneVerified: {
      name: "SetIsPhoneVerified",
      I: SetIsPhoneVerifiedRequest,
      O: SetIsPhoneVerifiedResponse,
      kind: MethodKind.Unary,
    },
    setTwoFactorVerification: {
      name: "SetTwoFactorVerification",
      I: SetTwoFactorVerificationRequest,
      O: SetTwoFactorVerificationResponse,
      kind: MethodKind.Unary,
    },
    setCasinoStatus: {
      name: "SetCasinoStatus",
      I: SetCasinoStatusRequest,
      O: SetCasinoStatusResponse,
      kind: MethodKind.Unary,
    },
    setBettingStatus: {
      name: "SetBettingStatus",
      I: SetBettingStatusRequest,
      O: SetBettingStatusResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetIsTest
   */
  public setIsTest(
    input: PartialMessage<SetIsTestRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsTestResponse> {
    return unary<SetIsTestRequest, SetIsTestResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsTest,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.UpdateProbablyGood
   */
  public updateProbablyGood(
    input: PartialMessage<UpdateProbablyGoodRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateProbablyGoodResponse> {
    return unary<UpdateProbablyGoodRequest, UpdateProbablyGoodResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateProbablyGood,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.UpdateAutoWithdrawalStatus
   */
  public updateAutoWithdrawalStatus(
    input: PartialMessage<UpdateAutoWithdrawalStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateAutoWithdrawalStatusResponse> {
    return unary<UpdateAutoWithdrawalStatusRequest, UpdateAutoWithdrawalStatusResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateAutoWithdrawalStatus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.UpdateHacked
   */
  public updateHacked(
    input: PartialMessage<UpdateHackedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateHackedResponse> {
    return unary<UpdateHackedRequest, UpdateHackedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateHacked,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.UpdateProject
   */
  public updateProject(
    input: PartialMessage<UpdateProjectRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateProjectResponse> {
    return unary<UpdateProjectRequest, UpdateProjectResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateProject,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetBlocked
   */
  public setBlocked(
    input: PartialMessage<SetBlockedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetBlockedResponse> {
    return unary<SetBlockedRequest, SetBlockedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setBlocked,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetUnblocked
   */
  public setUnblocked(
    input: PartialMessage<SetUnblockedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetUnblockedResponse> {
    return unary<SetUnblockedRequest, SetUnblockedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setUnblocked,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.GetRestrictions
   */
  public getRestrictions(
    input: PartialMessage<GetRestrictionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetRestrictionsResponse> {
    return unary<GetRestrictionsRequest, GetRestrictionsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.getRestrictions,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.UpdateRestrictions
   */
  public updateRestrictions(
    input: PartialMessage<UpdateRestrictionsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateRestrictionsResponse> {
    return unary<UpdateRestrictionsRequest, UpdateRestrictionsResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.updateRestrictions,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.PartialUpdateFinanceSection
   */
  public partialUpdateFinanceSection(
    input: PartialMessage<PartialUpdateFinanceSectionRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<PartialUpdateFinanceSectionResponse> {
    return unary<PartialUpdateFinanceSectionRequest, PartialUpdateFinanceSectionResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.partialUpdateFinanceSection,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetAccountVerified
   */
  public setAccountVerified(
    input: PartialMessage<SetAccountVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetAccountVerifiedResponse> {
    return unary<SetAccountVerifiedRequest, SetAccountVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setAccountVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetAccountReverification
   */
  public setAccountReverification(
    input: PartialMessage<SetAccountReverificationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetAccountReverificationResponse> {
    return unary<SetAccountReverificationRequest, SetAccountReverificationResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setAccountReverification,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetEmail
   */
  public setEmail(
    input: PartialMessage<SetEmailRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetEmailResponse> {
    return unary<SetEmailRequest, SetEmailResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setEmail,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetIsEmailVerified
   */
  public setIsEmailVerified(
    input: PartialMessage<SetIsEmailVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsEmailVerifiedResponse> {
    return unary<SetIsEmailVerifiedRequest, SetIsEmailVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsEmailVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetPhone
   */
  public setPhone(
    input: PartialMessage<SetPhoneRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetPhoneResponse> {
    return unary<SetPhoneRequest, SetPhoneResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setPhone,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetIsPhoneVerified
   */
  public setIsPhoneVerified(
    input: PartialMessage<SetIsPhoneVerifiedRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetIsPhoneVerifiedResponse> {
    return unary<SetIsPhoneVerifiedRequest, SetIsPhoneVerifiedResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setIsPhoneVerified,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetTwoFactorVerification
   */
  public setTwoFactorVerification(
    input: PartialMessage<SetTwoFactorVerificationRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetTwoFactorVerificationResponse> {
    return unary<SetTwoFactorVerificationRequest, SetTwoFactorVerificationResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setTwoFactorVerification,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetCasinoStatus
   */
  public setCasinoStatus(
    input: PartialMessage<SetCasinoStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetCasinoStatusResponse> {
    return unary<SetCasinoStatusRequest, SetCasinoStatusResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setCasinoStatus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc git.time2go.tech.apis.pinup.players.services.backoffice.PlayerService.SetBettingStatus
   */
  public setBettingStatus(
    input: PartialMessage<SetBettingStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetBettingStatusResponse> {
    return unary<SetBettingStatusRequest, SetBettingStatusResponse>(
      this.transport,
      PlayerRemoteService,
      PlayerRemoteService.methods.setBettingStatus,
      input,
      options,
    );
  }
}
