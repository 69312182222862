// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/platformintegrations/platformintegrations.proto (package pinup.players.player.platformintegrations, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetAddressesRequest, GetAddressesResponse, GetAffiliatesRequest, GetAffiliatesResponse, GetCurrencyInfosRequest, GetCurrencyInfosResponse } from "./platformintegrations_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.players.player.platformintegrations.Players
 */
@Injectable({ providedIn: "root" })
export class PlayersRemoteService {
  public static readonly typeName = "pinup.players.player.platformintegrations.Players";

  public static readonly methods = {
    getPlayersCurrencyInfo: {
      name: "GetPlayersCurrencyInfo",
      I: GetCurrencyInfosRequest,
      O: GetCurrencyInfosResponse,
      kind: MethodKind.Unary,
    },
    getPlayersAddress: {
      name: "GetPlayersAddress",
      I: GetAddressesRequest,
      O: GetAddressesResponse,
      kind: MethodKind.Unary,
    },
    getPlayersAffiliate: {
      name: "GetPlayersAffiliate",
      I: GetAffiliatesRequest,
      O: GetAffiliatesResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.players.player.platformintegrations.Players.GetPlayersCurrencyInfo
   */
  public getPlayersCurrencyInfo(
    input: PartialMessage<GetCurrencyInfosRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetCurrencyInfosResponse> {
    return unary<GetCurrencyInfosRequest, GetCurrencyInfosResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayersCurrencyInfo,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.platformintegrations.Players.GetPlayersAddress
   */
  public getPlayersAddress(
    input: PartialMessage<GetAddressesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAddressesResponse> {
    return unary<GetAddressesRequest, GetAddressesResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayersAddress,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.platformintegrations.Players.GetPlayersAffiliate
   */
  public getPlayersAffiliate(
    input: PartialMessage<GetAffiliatesRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetAffiliatesResponse> {
    return unary<GetAffiliatesRequest, GetAffiliatesResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayersAffiliate,
      input,
      options,
    );
  }
}
