// @generated by protoc-gen-es v1.10.0
// @generated from file services/backoffice/players.proto (package git.time2go.tech.apis.pinup.players.services.backoffice, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum git.time2go.tech.apis.pinup.players.services.backoffice.ErrorReason
 */
export const ErrorReason = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.players.services.backoffice.ErrorReason",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "PLAYER_IS_BLOCKED"},
    {no: 2, name: "ONLY_TEST_PLAYER_CAN_BE_EDITED"},
    {no: 3, name: "FIELD_VIOLATION"},
    {no: 4, name: "EMAIL_IS_ALREADY_TAKEN"},
    {no: 5, name: "PHONE_IS_ALREADY_TAKEN"},
  ],
);

/**
 * @generated from enum git.time2go.tech.apis.pinup.players.services.backoffice.ReverificationStatus
 */
export const ReverificationStatus = /*@__PURE__*/ proto3.makeEnum(
  "git.time2go.tech.apis.pinup.players.services.backoffice.ReverificationStatus",
  [
    {no: 0, name: "NO_NEEDED"},
    {no: 1, name: "DISABLE"},
    {no: 2, name: "REQUIRED"},
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsTestRequest
 */
export const SetIsTestRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsTestRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isTest", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * possible ErrorReasons: PLAYER_IS_BLOCKED
 *
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsTestResponse
 */
export const SetIsTestResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsTestResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProbablyGoodRequest
 */
export const UpdateProbablyGoodRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProbablyGoodRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "probablyGood", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProbablyGoodResponse
 */
export const UpdateProbablyGoodResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProbablyGoodResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateAutoWithdrawalStatusRequest
 */
export const UpdateAutoWithdrawalStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateAutoWithdrawalStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "offAWForPlayer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "offAWForPlayerReason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateAutoWithdrawalStatusResponse
 */
export const UpdateAutoWithdrawalStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateAutoWithdrawalStatusResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateHackedRequest
 */
export const UpdateHackedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateHackedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "hacked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateHackedResponse
 */
export const UpdateHackedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateHackedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProjectRequest
 */
export const UpdateProjectRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProjectRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProjectResponse
 */
export const UpdateProjectResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateProjectResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetBlockedRequest
 */
export const SetBlockedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetBlockedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "reason", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetBlockedResponse
 */
export const SetBlockedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetBlockedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetUnblockedRequest
 */
export const SetUnblockedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetUnblockedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetUnblockedResponse
 */
export const SetUnblockedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetUnblockedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.GetRestrictionsRequest
 */
export const GetRestrictionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.GetRestrictionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.GetRestrictionsResponse
 */
export const GetRestrictionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.GetRestrictionsResponse",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "restrictions", kind: "message", T: Restrictions },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateRestrictionsRequest
 */
export const UpdateRestrictionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateRestrictionsRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "restrictions", kind: "message", T: Restrictions },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.Restrictions
 */
export const Restrictions = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.Restrictions",
  () => [
    { no: 1, name: "restrictedCasinoReal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "restrictedSportReal", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 3, name: "restrictedCasinoBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "restrictedSportBonus", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "pincoinsExchange", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "blockSendEmail", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "blockCasinoTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "blockSportTournaments", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 9, name: "restrictedWelcomeBonusCasino", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 10, name: "restrictedWelcomeBonusSport", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 11, name: "turnoverOff", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.UpdateRestrictionsResponse
 */
export const UpdateRestrictionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.UpdateRestrictionsResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.PartialUpdateFinanceSectionRequest
 */
export const PartialUpdateFinanceSectionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.PartialUpdateFinanceSectionRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "cpf", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "taxId", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "dayLimit", kind: "scalar", T: 1 /* ScalarType.DOUBLE */, opt: true },
    { no: 5, name: "cpfConfirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "taxIdConfirmed", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "isNotePinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.PartialUpdateFinanceSectionResponse
 */
export const PartialUpdateFinanceSectionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.PartialUpdateFinanceSectionResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountVerifiedRequest
 */
export const SetAccountVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountVerifiedResponse
 */
export const SetAccountVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountReverificationRequest
 */
export const SetAccountReverificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountReverificationRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "reverificationStatus", kind: "enum", T: proto3.getEnumType(ReverificationStatus) },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountReverificationResponse
 */
export const SetAccountReverificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetAccountReverificationResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetEmailRequest
 */
export const SetEmailRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetEmailRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetEmailResponse
 */
export const SetEmailResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetEmailResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsEmailVerifiedRequest
 */
export const SetIsEmailVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsEmailVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isEmailVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsEmailVerifiedResponse
 */
export const SetIsEmailVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsEmailVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetPhoneRequest
 */
export const SetPhoneRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetPhoneRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetPhoneResponse
 */
export const SetPhoneResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetPhoneResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsPhoneVerifiedRequest
 */
export const SetIsPhoneVerifiedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsPhoneVerifiedRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "isPhoneVerified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetIsPhoneVerifiedResponse
 */
export const SetIsPhoneVerifiedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetIsPhoneVerifiedResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetTwoFactorVerificationRequest
 */
export const SetTwoFactorVerificationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetTwoFactorVerificationRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "turnOnTwoFactorAuth", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetTwoFactorVerificationResponse
 */
export const SetTwoFactorVerificationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetTwoFactorVerificationResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetCasinoStatusRequest
 */
export const SetCasinoStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetCasinoStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetCasinoStatusResponse
 */
export const SetCasinoStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetCasinoStatusResponse",
  [],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetBettingStatusRequest
 */
export const SetBettingStatusRequest = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetBettingStatusRequest",
  () => [
    { no: 1, name: "playerId", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "comment", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message git.time2go.tech.apis.pinup.players.services.backoffice.SetBettingStatusResponse
 */
export const SetBettingStatusResponse = /*@__PURE__*/ proto3.makeMessageType(
  "git.time2go.tech.apis.pinup.players.services.backoffice.SetBettingStatusResponse",
  [],
);

