import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

export const AMOUNT_OF_KOPECKS = 100;

@Pipe({
  name: 'fromKopecks',
  standalone: true,
})
export class FromKopecksPipe implements PipeTransform {
  public transform(value: bigint, digits = 2): string {
    return (Number(value) / AMOUNT_OF_KOPECKS).toFixed(digits);
  }
}
