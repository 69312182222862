import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { PaginationControlBase } from './pagination-control.base';
import type { Pagination } from './pagination-panel.model';
import { PAGINATION_PANEL_MANAGER } from './pagination-panel.providers';

@Directive({
  selector: '[boManagePagination]',
  standalone: true,
})
export class ManagePaginationDirective extends PaginationControlBase {
  private readonly paginationManager = inject(PAGINATION_PANEL_MANAGER);
  private readonly pagination$ = this.paginationManager.source$;

  constructor() {
    super();

    this.pagination$.pipe(takeUntilDestroyed()).subscribe((pagination) => {
      this.valueAccessor?.writeValue(pagination);
    });
  }

  protected onChange(value: Partial<Pagination>): void {
    this.paginationManager.update(value);
  }
}
