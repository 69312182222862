import type { Department, License } from '@bo/common';

const GROUP_REGEX =
  /^(?<department>\w+).(?<license>\w+).(?<environment>\w+).(?<position>\w+).(?<unused0>\w+)$/;

export type AccessGroupLicense = string & {
  readonly __accessGroupLicense: unique symbol;
};

export type AccessGroupDepartment =
  | 'bo_dev'
  | 'bo_fin'
  | 'bo_gamificantion'
  | 'bo_qa'
  | 'bo_risk'
  | 'bo_sm'
  | 'bo_support';

export class AccessGroup {
  public static parse(group: string): AccessGroup {
    const result = GROUP_REGEX.exec(group)?.groups as
      | Record<keyof AccessGroup, string>
      | undefined;

    if (result === undefined) {
      throw new TypeError(`Incompatible group ${group}`);
    }

    return new AccessGroup(
      result.department as AccessGroupDepartment,
      result.license as AccessGroupLicense,
      result.environment,
      result.position,
      result.unused0,
    );
  }

  public static supports(
    department: Department,
    license: License,
    groups: readonly AccessGroup[],
  ): boolean {
    return groups.some((group) => group.supports(department, license));
  }

  private constructor(
    public readonly department: AccessGroupDepartment,
    public readonly license: AccessGroupLicense,
    public readonly environment: string,
    public readonly position: string,
    public readonly unused0: string,
  ) {}

  public supports(department: Department, license: License): boolean {
    return (
      department.groups.has(this.department) &&
      // TODO: Rethink this.license. Actually, it can be not only license, but brand as well.
      // For example, there can be the following token - "bo_dev.wl.dev.extended.manual",
      // where wl is not license, but brand which relates to com license
      (license.group === this.license || license.brand === this.license)
    );
  }
}
