export enum ProfileSessionsColumns {
  IP = 'ipAddress',
  SOURCE = 'project',
  DEVICE = 'device',
  LOCATION = 'location',
  DATE = 'createdAt',
}

export const DEFAULT_ACTIVE_SESSIONS_DATA_SHOW: { from: number; to: number } = {
  from: 0,
  to: 10,
};
