// @generated by protoc-gen-es v1.10.0
// @generated from file base/bo/v1/avatar.proto (package gamification.base.bo.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { AvatarRelatedEvent, AvatarStatus } from "./avatar_enum_pb.js";

/**
 * definition of message for avatar
 *
 * @generated from message gamification.base.bo.v1.Avatar
 */
export const Avatar = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.bo.v1.Avatar",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AvatarLangContent} },
    { no: 4, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "is_default", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_blurred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "status", kind: "enum", T: proto3.getEnumType(AvatarStatus) },
    { no: 11, name: "related_event", kind: "enum", T: proto3.getEnumType(AvatarRelatedEvent), opt: true },
  ],
);

/**
 * definition of message for information in different languages
 *
 * @generated from message gamification.base.bo.v1.AvatarLangContent
 */
export const AvatarLangContent = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.bo.v1.AvatarLangContent",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * definition of message for avatar list
 *
 * @generated from message gamification.base.bo.v1.AvatarList
 */
export const AvatarList = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.bo.v1.AvatarList",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_count", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 5, name: "published_at", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(AvatarStatus) },
    { no: 8, name: "created_at", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "related_event", kind: "enum", T: proto3.getEnumType(AvatarRelatedEvent), opt: true },
  ],
);

