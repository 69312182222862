import { inject, InjectionToken } from '@angular/core';
import { isEqual } from 'lodash-es';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs';

import type { PaginationPanelPayload, RouteParams } from '@bo/common';
import { ROUTE_PARAMS_PERSISTENCE } from '@bo/common';

import { parsePaginationPayloadFromRouteParams } from './route-params.utils';

export const PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE = new InjectionToken<
  Observable<PaginationPanelPayload>
>('PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE');

export const paginationPersistenceFactory: () => Observable<PaginationPanelPayload> =
  () =>
    inject(ROUTE_PARAMS_PERSISTENCE).pipe(
      map(({ page, pageSize }) => ({ page, pageSize })),
      distinctUntilChanged<RouteParams>(isEqual),
      map(parsePaginationPayloadFromRouteParams),
      shareReplay(1),
    );
