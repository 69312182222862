// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file services/promo/promo.proto (package pinup.players.player.promo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { GetPincoinsRequest, GetPincoinsResponse, SetPincoinsRequest, SetPincoinsResponse } from "./promo_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service pinup.players.player.promo.Players
 */
@Injectable({ providedIn: "root" })
export class PlayersRemoteService {
  public static readonly typeName = "pinup.players.player.promo.Players";

  public static readonly methods = {
    getPlayerPincoins: {
      name: "GetPlayerPincoins",
      I: GetPincoinsRequest,
      O: GetPincoinsResponse,
      kind: MethodKind.Unary,
    },
    setPlayerPincoins: {
      name: "SetPlayerPincoins",
      I: SetPincoinsRequest,
      O: SetPincoinsResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc pinup.players.player.promo.Players.GetPlayerPincoins
   */
  public getPlayerPincoins(
    input: PartialMessage<GetPincoinsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetPincoinsResponse> {
    return unary<GetPincoinsRequest, GetPincoinsResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.getPlayerPincoins,
      input,
      options,
    );
  }

  /**
   * @generated from rpc pinup.players.player.promo.Players.SetPlayerPincoins
   */
  public setPlayerPincoins(
    input: PartialMessage<SetPincoinsRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SetPincoinsResponse> {
    return unary<SetPincoinsRequest, SetPincoinsResponse>(
      this.transport,
      PlayersRemoteService,
      PlayersRemoteService.methods.setPlayerPincoins,
      input,
      options,
    );
  }
}
