import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CurrentRouteNavigationService } from '@pu/sdk';

import { PaginationManagerBase } from '../../../../../pagination-management/pagination-manager.base';
import type { PaginationPanelPayload } from '../../../../pagination-panel.model';
import { PAGINATION_PANEL_INITIAL_STATE } from '../../../../pagination-panel.providers';
import { parsePaginationPayloadFromRouteParams } from './route-params.utils';
import { PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE } from './route-params-persistence.providers';

@Injectable()
export class RouteParamsPersistenceManager extends PaginationManagerBase<PaginationPanelPayload> {
  private readonly route = inject(ActivatedRoute);
  private readonly navigationService = inject(CurrentRouteNavigationService);
  public readonly source$ = inject(PAGINATION_PANEL_ROUTE_PARAMS_PERSISTENCE);

  constructor() {
    super();

    const initialState = inject(PAGINATION_PANEL_INITIAL_STATE, {
      optional: true,
    });

    if (initialState) {
      this.update(initialState);
    }
  }

  public update(payload: Partial<PaginationPanelPayload>): void {
    const payloadFromRouteParams = parsePaginationPayloadFromRouteParams(
      this.route.snapshot.params,
    );

    const actualPayload = {
      page: payload.currentPage || payloadFromRouteParams.currentPage,
      pageSize: payload.pageSize || payloadFromRouteParams.pageSize,
    };

    this.navigationService.setParams(actualPayload, false, {
      replaceUrl: true,
      queryParamsHandling: 'preserve',
    });
  }
}
