import { inject, Injectable } from '@angular/core';
import type { Sort } from '@angular/material/sort';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_SessionType as SessionType } from '@pu/grpc/enums';
import type { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_PlayerSession as PlayerSession } from '@pu/grpc/messages';
import { PlayerService } from '@bo/common';

import { LoadMorePaginationService } from '../../pagination/load-more-pagination/load-more-pagination.service';
import { PAGINATION_LOAD_ALL_MAX } from '../../pagination/pagination-panel/pagination.const';
import { DEFAULT_ACTIVE_SESSIONS_DATA_SHOW } from './profile-sessions.const';
import { ProfileSessionsProviderService } from './profile-sessions.provider';
import { sortSessions } from './profile-sessions.utils';

@Injectable()
export class ProfileSessionsService {
  public sessionsProviderService = inject(ProfileSessionsProviderService);
  public readonly playerId$ = inject(PlayerService).playerId$;
  private readonly inactivePaginationService = inject(
    LoadMorePaginationService,
  );
  private readonly inactivePagination$ =
    this.inactivePaginationService.pagination$;

  public readonly isAllActiveSessions$ = new BehaviorSubject(false);
  public readonly activeSessionsSortData$ = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });
  public readonly inactiveSessionsSortData$ = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });
  private readonly inactiveSessionsTriggers$ = combineLatest([
    this.inactivePagination$,
    this.playerId$,
  ]);

  public rawActiveSessions$ = this.playerId$.pipe(
    switchMap((playerId) =>
      this.sessionsProviderService.findPlayerSession(
        playerId,
        SessionType.ACTIVE,
        PAGINATION_LOAD_ALL_MAX,
      ),
    ),
    shareReplay(1),
  );

  public rawInactiveSessions$ = this.inactiveSessionsTriggers$.pipe(
    switchMap(([pagination, playerId]) =>
      this.sessionsProviderService.findPlayerSession(
        playerId,
        SessionType.INACTIVE,
        pagination,
      ),
    ),
    shareReplay(1),
  );

  public activeSessions$ = this.rawActiveSessions$.pipe(
    map(({ sessions }) => sessions),
  );

  public inactiveSessions$ = this.rawInactiveSessions$.pipe(
    map(({ sessions }) => sessions),
  );

  public activeSessionsCount$ = this.rawActiveSessions$.pipe(
    map(({ count }) => count),
  );

  public inactiveSessionsCount$ = this.rawInactiveSessions$.pipe(
    map(({ count }) => count),
  );

  public slicedActiveSessions$: Observable<PlayerSession[]> = merge(
    this.activeSessions$,
    this.isAllActiveSessions$,
  ).pipe(
    withLatestFrom(this.activeSessions$, this.isAllActiveSessions$),
    map(([, sessions, isAllActiveSessions]) =>
      isAllActiveSessions
        ? sessions
        : sessions.slice(
            DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.from,
            DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.to,
          ),
    ),
  );

  public sortedActiveSessions$: Observable<PlayerSession[]> = merge(
    this.slicedActiveSessions$,
    this.activeSessionsSortData$,
  ).pipe(
    withLatestFrom(this.slicedActiveSessions$, this.activeSessionsSortData$),
    map(([, slicedActiveSessions, sort]) => {
      return sortSessions(slicedActiveSessions, sort);
    }),
  );

  public sortedInactiveSessions$: Observable<PlayerSession[]> = merge(
    this.inactiveSessions$,
    this.inactiveSessionsSortData$,
  ).pipe(
    withLatestFrom(this.inactiveSessions$, this.inactiveSessionsSortData$),
    map(([, inactiveSessions, sort]) => {
      return sortSessions(inactiveSessions, sort);
    }),
  );

  public loadingActiveSessions$ = merge(
    this.playerId$.pipe(map(() => true)),
    this.rawActiveSessions$.pipe(
      map(() => false),
      catchError(() => of(false)),
    ),
  ).pipe(shareReplay(1));

  public loadingInactiveSessions$ = merge(
    this.inactiveSessionsTriggers$.pipe(map(() => true)),
    this.rawInactiveSessions$.pipe(
      map(() => false),
      catchError(() => of(false)),
    ),
  ).pipe(shareReplay(1));

  public setSort(type: SessionType, sort: Sort): void {
    if (type === SessionType.ACTIVE) {
      this.activeSessionsSortData$.next(sort);
    } else {
      this.inactiveSessionsSortData$.next(sort);
    }
  }

  public toggleActiveSession(): void {
    this.isAllActiveSessions$.next(!this.isAllActiveSessions$.getValue());
  }
}
