// Copyright 2021-2024 The Connect Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @private Internal code, does not follow semantic versioning.
 */
export const headerContentType = "Content-Type";
export const headerEncoding = "Grpc-Encoding";
export const headerAcceptEncoding = "Grpc-Accept-Encoding";
export const headerTimeout = "Grpc-Timeout";
export const headerGrpcStatus = "Grpc-Status";
export const headerGrpcMessage = "Grpc-Message";
export const headerStatusDetailsBin = "Grpc-Status-Details-Bin";
export const headerMessageType = "Grpc-Message-Type";
export const headerUserAgent = "User-Agent";
