import type { PaginationPanelPayload, RouteParams } from '@bo/common';
import { DEFAULT_PAGINATION } from '@bo/common';

export const parsePaginationPayloadFromRouteParams = ({
  page,
  pageSize,
}: RouteParams): PaginationPanelPayload => ({
  currentPage: page ? Number(page) : DEFAULT_PAGINATION.currentPage,
  pageSize: pageSize ? Number(pageSize) : DEFAULT_PAGINATION.pageSize,
});
