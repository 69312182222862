import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const DEFAULT_LIMIT = 10n;
export const DEFAULT_OFFSET = 0n;

export interface LoadMorePagination {
  limit: bigint;
  offset: bigint;
}

// TODO: connect to our pagination manager, need refactor
@Injectable()
export class LoadMorePaginationService {
  public pagination$ = new BehaviorSubject<LoadMorePagination>({
    limit: DEFAULT_LIMIT,
    offset: DEFAULT_OFFSET,
  });
  // we use limit(not offset) to avoid previous inactive sessions save
  public currentLimit$ = new BehaviorSubject<bigint>(DEFAULT_LIMIT);

  get pagination(): LoadMorePagination {
    return this.pagination$.getValue();
  }

  get currentLimit(): bigint {
    return this.currentLimit$.getValue();
  }

  public setPagination(pagination: LoadMorePagination): void {
    this.pagination$.next(pagination);
  }

  public setDefaultPagination(): void {
    this.pagination$.next({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET });
  }

  public setLimit(limit: bigint): void {
    this.currentLimit$.next(limit);
  }

  public loadMore(): void {
    this.pagination$.next({
      ...this.pagination,
      limit: this.pagination.limit + this.currentLimit,
    });
  }

  public loadLess(): void {
    this.pagination$.next({
      ...this.pagination,
      limit: this.pagination.limit - this.currentLimit,
    });
  }
}
