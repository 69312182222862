import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { KeyValuePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButton } from '@angular/material/button';
import { MatChip } from '@angular/material/chips';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlayerRemoteService } from '@pinup-grpc/services/backoffice/players_ng';
import { CasinoStatus } from '@pinup-grpc/services/bo/admin/enum_pb';
import type { Observable } from 'rxjs';
import { ignoreElements, tap } from 'rxjs';

import {
  casinoIcon,
  DialogContentComponent,
  DialogSuspenseDirective,
  extractEnumReverseEntries,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  SvgComponent,
  ValidationErrorPipe,
} from '@pu/sdk';
import {
  CASINO_STATUS_NAMES_MAP,
  casinoStatusesMap,
  emptyBackground,
  getStatusFromMap,
  LicenseSupportsPipe,
  PlayerService,
} from '@bo/common';

import { BaseDialogWithNoteComponent } from '../base-dialog-with-note/base-dialog-with-note.component';
import { CASINO_DIALOG_DATA } from './casino-status-dialog.model';

@Component({
  selector: 'bo-casino-status-dialog',
  standalone: true,
  imports: [
    DialogContentComponent,
    DialogSuspenseDirective,
    FormsModule,
    SuspenseAreaComponent,
    InertSuspenseDirective,
    SuspenseDirective,
    SvgComponent,
    MatDialogTitle,
    MatFormField,
    StrictMinLengthDirective,
    CdkTextareaAutosize,
    MatInput,
    ValidationErrorPipe,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatLabel,
    MatError,
    LicenseSupportsPipe,
    MatOption,
    MatSelect,
    MatChip,
    KeyValuePipe,
  ],
  templateUrl: './casino-status-dialog.component.html',
  styleUrl: '../base-dialog-with-note/base-dialog-with-note.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CasinoStatusDialogComponent extends BaseDialogWithNoteComponent {
  protected readonly data = inject(CASINO_DIALOG_DATA);
  private readonly playerRemoteService = inject(PlayerRemoteService);
  private readonly snackBar = inject(MatSnackBar);
  private readonly playerService = inject(PlayerService);

  protected readonly casinoStatusNames = CASINO_STATUS_NAMES_MAP;
  protected readonly casinoStatusOptions =
    extractEnumReverseEntries(CasinoStatus);
  protected readonly casinoStatusesMap = casinoStatusesMap;
  protected readonly getStatusFromMap = getStatusFromMap;

  public casinoStatus = signal(this.data.casinoStatus);

  protected readonly emptyBackground = emptyBackground;
  protected readonly casinoIcon = casinoIcon;

  public changeProfileData(): Observable<never> {
    return this.playerRemoteService
      .setCasinoStatus({
        // TODO: after BE impl send just this.casinoStatus()
        status: CasinoStatus[this.casinoStatus()],
        comment: this.note(),
        // isNotePinned: this.pinned(),
        playerId: this.data.playerId,
      })
      .pipe(
        tap(() => {
          this.snackBar.open('Casino status changed', 'Close', {
            duration: 5000,
          });
        }),
        ignoreElements(),
      );
  }
}
