import { InjectionToken } from '@angular/core';

export type RequestType =
  | 'disable-2fa'
  | 'disable-aw'
  | 'enable-2fa'
  | 'enable-aw'
  | 'send-email'
  | 'set-default'
  | 'set-test'
  | 'unverify-email'
  | 'unverify-phone'
  | 'unverify-player'
  | 'verify-email'
  | 'verify-phone'
  | 'verify-player';

export type DialogTitles = Readonly<{
  title: string;
  subTitle?: string;
}>;

export type DialogWithNoteData = DialogTitles & {
  requestType: RequestType;
};

export const DIALOG_WITH_NOTE_DATA = new InjectionToken<DialogWithNoteData>(
  'DIALOG_WITH_NOTE_DATA',
);
