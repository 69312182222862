import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import type { SumCurrency } from '@pinup-grpc/base/models_pb';

import { MoneyComponent } from '@pu/sdk';

import type { PaginationTotal } from '../pagination/pagination-panel/features/total-info/total-info.model';

@Component({
  selector: 'bo-finance-statistic',
  standalone: true,
  imports: [MoneyComponent],
  templateUrl: './finance-statistic.component.html',
  styleUrl: './finance-statistic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinanceStatisticComponent {
  public total = input<PaginationTotal | null>();
  public totalAmount = input<SumCurrency | null>();
}
