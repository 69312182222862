import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import type {
  FindPlayerSessionsResponse,
  SessionType,
} from '@pinup-grpc/services/backoffice/sessions_pb';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs';

import { SessionsRemoteService } from '@pu/grpc';

import type { LoadMorePagination } from '../../pagination/load-more-pagination/load-more-pagination.service';

@Injectable()
export class ProfileSessionsProviderService {
  public sessionsService = inject(SessionsRemoteService);
  private readonly snackBar = inject(MatSnackBar);

  public findPlayerSession(
    playerId: bigint,
    type: SessionType,
    pagination: LoadMorePagination,
  ): Observable<FindPlayerSessionsResponse> {
    return this.sessionsService
      .findPlayerSession({
        playerId,
        type,
        limit: pagination.limit,
        offset: pagination.offset,
      })
      .pipe(
        catchError(() => {
          this.snackBar.open('Can`t get sessions, try again', 'OK', {
            duration: 5000,
          });

          return [];
        }),
      );
  }
}
