<form
  suspense-area
  (ngSubmit)="suspense.run(changeProfileData())"
  puDialogSuspense
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>
    <div class="header">
      <div class="header-icon">
        <bo-svg class="circles" [unsafeSvgSource]="emptyBackground" />
        <bo-svg class="alert" [size]="24" [unsafeSvgSource]="casinoIcon" />
      </div>
      <div class="header-text">
        <span class="text">{{ data.title }}</span>
      </div>
    </div>
  </div>
  <pu-dialog-content>
    @if ('casino' | licenseSupports) {
      <mat-form-field class="field">
        <mat-label>Betting status</mat-label>
        <mat-select
          [(ngModel)]="casinoStatus"
          data-testid="bettingStatus"
          name="bettingStatus"
        >
          @for (status of casinoStatusOptions | keyvalue; track status.key) {
            <mat-option [value]="status.key">
              <mat-chip
                class="chip-status"
                [class]="
                  getStatusFromMap(casinoStatusesMap, status.key).className
                "
              >
                {{ status.value }}
              </mat-chip>
              -

              {{ casinoStatusNames[status.key] }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
    <mat-form-field class="field">
      <mat-label>Note</mat-label>
      <textarea
        [(ngModel)]="note"
        [puStrictMinLength]="2"
        cdkAutosizeMinRows="2"
        cdkTextareaAutosize
        matInput
        name="note"
        placeholder="Some text"
        #commentField="ngModel"
      ></textarea>
      @if (commentField | validationError; as error) {
        <mat-error>{{ error }}</mat-error>
      }
    </mat-form-field>
    <!--  TODO: uncomment after BE impl -->
    <!--    <mat-checkbox [(ngModel)]="pinned" name="pinned">Pin note</mat-checkbox>-->
  </pu-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-button
      class="button"
      [disabled]="suspense.loading()"
      matDialogClose
      type="button"
    >
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="suspense.loading() || form.invalid"
      color="primary"
      type="submit"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</form>
