import { InjectionToken } from '@angular/core';
import type { CasinoStatus } from '@pinup-grpc/services/bo/players/players_pb';

import type { DialogTitles } from '../base-dialog-with-note/dialog-with-note.model';

export type casinoDialogData = DialogTitles &
  Readonly<{
    playerId: bigint;
    casinoStatus: CasinoStatus;
  }>;

export const CASINO_DIALOG_DATA = new InjectionToken<casinoDialogData>(
  'CASINO_DIALOG_DATA',
);
