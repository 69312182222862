// @generated by protoc-gen-es v1.10.0
// @generated from file base/frontend/v1/avatar.proto (package gamification.base.frontend.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { PlayerAvatarRelatedEvent } from "./player_enum_pb.js";

/**
 * definition of message for avatar list
 *
 * @generated from message gamification.base.frontend.v1.AvatarList
 */
export const AvatarList = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.base.frontend.v1.AvatarList",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "is_active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_blurred", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "related_event", kind: "enum", T: proto3.getEnumType(PlayerAvatarRelatedEvent), opt: true },
    { no: 9, name: "related_event_access_time", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

