// @generated by protoc-gen-es v1.10.0
// @generated from file events/antifraud_update.proto (package payments, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message payments.RequestForATFPaymentScoring
 */
export const RequestForATFPaymentScoring = /*@__PURE__*/ proto3.makeMessageType(
  "payments.RequestForATFPaymentScoring",
  [],
);

/**
 * payments event
 * NATS:
 * subjects:
 *   - pinup.product.<license>.atf.deposit.success.event
 *   - pinup.product.<license>.atf.deposit.failed.event
 *   - pinup.product.<license>.atf.withdraw.success.event
 *   - pinup.product.<license>.atf.withdraw.failed.event
 *   - pinup.product.<license>.atf.withdraw.cancel.event
 *
 * @generated from message payments.ResponseForATFPaymentScoring
 */
export const ResponseForATFPaymentScoring = /*@__PURE__*/ proto3.makeMessageType(
  "payments.ResponseForATFPaymentScoring",
  () => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "status_bool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "charge_back", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "external_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "masked_payment_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "number_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "pay_way", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "token_tnx", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "transaction_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "fp_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "approved_at", kind: "message", T: Timestamp },
    { no: 14, name: "updated_at", kind: "message", T: Timestamp },
    { no: 15, name: "player_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 16, name: "card_bin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "payment_id_cashbox", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "card_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "amount_eur", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 20, name: "payment_number_hash", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "created_at", kind: "message", T: Timestamp },
    { no: 23, name: "project", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 25, name: "merchant_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 26, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 27, name: "cardholder", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "confirm_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 29, name: "account_region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 30, name: "wdr_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

