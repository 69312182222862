<bo-list-state-wrapper
  [hasData]="hasData$ | async"
  [hasError]="hasError$ | async"
  [isLoading]="isLoading$ | async"
>
  <ng-container data>
    @if (paymentMethods(); as paymentMethods) {
      <table
        mat-table
        class="table-element"
        (matSortChange)="changeSort($event)"
        [dataSource]="paymentMethods"
        [matSortDisableClear]="true"
        [matSortDisabled]="!paymentMethods.length"
        matSort
        matSortActive="lastActivityAt"
        matSortDirection="desc"
      >
        <ng-container [matColumnDef]="Column.GEO">
          <th *matHeaderCellDef mat-header-cell>Geo</th>
          <td *matCellDef="let element" mat-cell>
            <bo-geo [value]="element.lastActivityGeo" bordered />
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.DEPOSIT">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            arrowPosition="before"
            sortActionDescription="Sort by Deposit sumSuccessful"
          >
            Deposit
          </th>
          <td *matCellDef="let element" mat-cell>
            <div
              class="deposit-cell cell-align-right"
              [class.success-color]="element.deposit.sumSuccessful > 0"
            >
              <amount-formatter
                [numberToFormat]="element.deposit.sumSuccessful"
                sign="+"
              />
              <span class="currency-label">{{ element.currency }}</span>
              <p class="transactions-count">
                <span
                  [matTooltip]="transactionTooltips.successful"
                  matTooltipPosition="above"
                >
                  {{ element.deposit.countSuccessful }}
                </span>
                |
                <span
                  [matTooltip]="transactionTooltips.unsuccessful"
                  matTooltipPosition="above"
                >
                  {{ element.deposit.countFailed }}
                </span>
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.WITHDRAWAL">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            arrowPosition="before"
            sortActionDescription="Sort by Withdrawal sumSuccessful"
          >
            Withdrawal
          </th>
          <td *matCellDef="let element" mat-cell>
            <div
              class="withdrawal-cell cell-align-right"
              [class.danger-color]="element.withdrawal.sumSuccessful > 0"
            >
              <amount-formatter
                [numberToFormat]="element.withdrawal.sumSuccessful"
                sign="-"
              />
              <span class="currency-label">{{ element.currency }}</span>
              <p class="transactions-count">
                <span
                  [matTooltip]="transactionTooltips.successful"
                  matTooltipPosition="above"
                >
                  {{ element.withdrawal.countSuccessful }}
                </span>
                |
                <span
                  [matTooltip]="transactionTooltips.unsuccessful"
                  matTooltipPosition="above"
                >
                  {{ element.withdrawal.countFailed }}
                </span>
              </p>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.PSP">
          <th *matHeaderCellDef mat-header-cell>
            <div class="psp-header-cell">
              <span class="header-label">PSP</span>
              <bo-svg
                [unsafeSvgSource]="questionCircle"
                matTooltip="Payment service provider"
                matTooltipPosition="above"
              />
            </div>
          </th>

          <td *matCellDef="let element" mat-cell>
            <div class="psp-cell">
              <span
                class="payment-number flex align-items-center"
                [class.warning-color]="!element.deposit.countSuccessful"
              >
                {{ element.paymentNumber }}
                @if (!element.deposit.countSuccessful) {
                  <bo-svg
                    class="icon flex pl-1"
                    [size]="20"
                    [unsafeSvgSource]="alertIcon"
                  />
                }
              </span>
              <span class="psp-type">Account number</span>
            </div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="Column.LAST_ACTIVITY">
          <th
            *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            arrowPosition="before"
            sortActionDescription="Sort by date"
          >
            <span class="header-column-title">Date of last transaction</span>
          </th>
          <td *matCellDef="let element" mat-cell>
            <div class="date-cell">
              <time class="global-date">
                {{ element.lastActivityAt | toDate | date: 'yyyy-MM-dd' }}
              </time>
              |
              <time class="exact-time">
                {{ element.lastActivityAt | toDate | date: 'HH:mm:ss' }}
              </time>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns" mat-header-row class="table-header"></tr>
        <tr *matRowDef="let row; columns: columns" mat-row></tr>
      </table>
    }

    @if (total$ | async; as totalInfo) {
      @if (totalInfo.totalPages! > 1) {
        <bo-pagination-panel
          [totalItems]="totalInfo.totalItems"
          [totalPages]="totalInfo.totalPages"
          boManagePagination
        />
      }
    }
  </ng-container>
  <bo-empty-template [message]="EMPTY_TEXT_PLACEHOLDER" empty />

  <bo-empty-template
    [isError]="true"
    error
    message="No payment methods found"
    prompt="System error"
  />
</bo-list-state-wrapper>
