import { signal } from '@angular/core';
import type { Observable } from 'rxjs';

import type { DialogTitles } from './dialog-with-note.model';

export abstract class BaseDialogWithNoteComponent {
  protected abstract readonly data: Pick<DialogTitles, 'title'>;

  public note = signal('');
  public pinned = signal(false);

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  public abstract changeProfileData(): Observable<never> | void;
}
