// @generated by @pinup/protoc-gen-ng v0.0.0 with parameter "target=ts,mock"
// @generated from file service/backoffice/withdrawals/withdrawals.proto (package payments.backoffice.withdrawals, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { inject, Injectable } from "@angular/core";
import { AcceptByAntiFraudRequest, AcceptByAntiFraudResponse, AcceptRequest, AcceptResponse, ChangePaymentStatusRequest, ChangePaymentStatusResponse, DeclineRequest, DeclineResponse, GetWithdrawalByIdRequest, GetWithdrawalByIdResponse, SendToAntiFraudRequest, SendToAntiFraudResponse, UpdateWithdrawalInStatRequest, UpdateWithdrawalInStatResponse } from "./withdrawals_pb.js";
import type { PartialMessage } from "@bufbuild/protobuf";
import { MethodKind } from "@bufbuild/protobuf";
import type { ReactiveTransportOptions } from "@pinup/grpc-ng";
import { GRPC_TRANSPORT, unary } from "@pinup/grpc-ng";
import type { Observable } from "rxjs";

/**
 * @generated from service payments.backoffice.withdrawals.WithdrawalService
 */
@Injectable({ providedIn: "root" })
export class WithdrawalRemoteService {
  public static readonly typeName = "payments.backoffice.withdrawals.WithdrawalService";

  public static readonly methods = {
    getWithdrawalById: {
      name: "GetWithdrawalById",
      I: GetWithdrawalByIdRequest,
      O: GetWithdrawalByIdResponse,
      kind: MethodKind.Unary,
    },
    accept: {
      name: "Accept",
      I: AcceptRequest,
      O: AcceptResponse,
      kind: MethodKind.Unary,
    },
    decline: {
      name: "Decline",
      I: DeclineRequest,
      O: DeclineResponse,
      kind: MethodKind.Unary,
    },
    sendToAntiFraud: {
      name: "SendToAntiFraud",
      I: SendToAntiFraudRequest,
      O: SendToAntiFraudResponse,
      kind: MethodKind.Unary,
    },
    acceptByAntiFraud: {
      name: "AcceptByAntiFraud",
      I: AcceptByAntiFraudRequest,
      O: AcceptByAntiFraudResponse,
      kind: MethodKind.Unary,
    },
    changePaymentStatus: {
      name: "ChangePaymentStatus",
      I: ChangePaymentStatusRequest,
      O: ChangePaymentStatusResponse,
      kind: MethodKind.Unary,
    },
    updateWithdrawalInStatById: {
      name: "UpdateWithdrawalInStatById",
      I: UpdateWithdrawalInStatRequest,
      O: UpdateWithdrawalInStatResponse,
      kind: MethodKind.Unary,
    },
  } as const;

  private readonly transport = inject(GRPC_TRANSPORT);

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.GetWithdrawalById
   */
  public getWithdrawalById(
    input: PartialMessage<GetWithdrawalByIdRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<GetWithdrawalByIdResponse> {
    return unary<GetWithdrawalByIdRequest, GetWithdrawalByIdResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.getWithdrawalById,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.Accept
   */
  public accept(
    input: PartialMessage<AcceptRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AcceptResponse> {
    return unary<AcceptRequest, AcceptResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.accept,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.Decline
   */
  public decline(
    input: PartialMessage<DeclineRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<DeclineResponse> {
    return unary<DeclineRequest, DeclineResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.decline,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.SendToAntiFraud
   */
  public sendToAntiFraud(
    input: PartialMessage<SendToAntiFraudRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<SendToAntiFraudResponse> {
    return unary<SendToAntiFraudRequest, SendToAntiFraudResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.sendToAntiFraud,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.AcceptByAntiFraud
   */
  public acceptByAntiFraud(
    input: PartialMessage<AcceptByAntiFraudRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<AcceptByAntiFraudResponse> {
    return unary<AcceptByAntiFraudRequest, AcceptByAntiFraudResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.acceptByAntiFraud,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.ChangePaymentStatus
   */
  public changePaymentStatus(
    input: PartialMessage<ChangePaymentStatusRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<ChangePaymentStatusResponse> {
    return unary<ChangePaymentStatusRequest, ChangePaymentStatusResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.changePaymentStatus,
      input,
      options,
    );
  }

  /**
   * @generated from rpc payments.backoffice.withdrawals.WithdrawalService.UpdateWithdrawalInStatById
   */
  public updateWithdrawalInStatById(
    input: PartialMessage<UpdateWithdrawalInStatRequest>,
    options?: ReactiveTransportOptions,
  ): Observable<UpdateWithdrawalInStatResponse> {
    return unary<UpdateWithdrawalInStatRequest, UpdateWithdrawalInStatResponse>(
      this.transport,
      WithdrawalRemoteService,
      WithdrawalRemoteService.methods.updateWithdrawalInStatById,
      input,
      options,
    );
  }
}
