import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PlayerRemoteService } from '@pinup-grpc/services/players/players_ng';

import {
  DialogContentComponent,
  DialogSuspenseDirective,
  maskedPhone,
  MaskedValueAccessor,
  StrictPhoneDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  ValidationErrorPipe,
} from '@pu/sdk';

export interface ChangePhoneDialogData {
  readonly profileId: bigint;
  readonly phone: string;
}

export const CHANGE_PHONE_DIALOG_DATA =
  new InjectionToken<ChangePhoneDialogData>('CHANGE_PHONE_DIALOG_DATA');

@Component({
  selector: 'bo-change-phone-dialog',
  standalone: true,
  imports: [
    SuspenseAreaComponent,
    DialogSuspenseDirective,
    SuspenseDirective,
    MatDialogTitle,
    FormsModule,
    MatFormField,
    MatInput,
    MaskedValueAccessor,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    MatLabel,
    MatError,
    ValidationErrorPipe,
    StrictPhoneDirective,
    DialogContentComponent,
  ],
  templateUrl: './change-phone-dialog.component.html',
  styleUrls: ['../../stylesheets/components/_popup.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePhoneDialogComponent {
  protected readonly data = inject(CHANGE_PHONE_DIALOG_DATA);
  protected readonly playerRemoteService = inject(PlayerRemoteService);

  protected readonly phone = signal(this.data.phone);

  protected readonly phoneMask = maskedPhone();
}
