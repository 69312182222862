// @generated by protoc-gen-es v1.10.0
// @generated from file base/insvc/v1/achievement.proto (package gamification.achievement_manager.base.insvc.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AccomplishmentOrder, AchievementStatus } from "./achievement_enum_pb.js";
import { Task } from "./task_pb.js";

/**
 * definition of message for information in different languages
 *
 * @generated from message gamification.achievement_manager.base.insvc.v1.AchievementLangContent
 */
export const AchievementLangContent = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.insvc.v1.AchievementLangContent",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * definition of message for achievement
 *
 * @generated from message gamification.achievement_manager.base.insvc.v1.Achievement
 */
export const Achievement = /*@__PURE__*/ proto3.makeMessageType(
  "gamification.achievement_manager.base.insvc.v1.Achievement",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "jpg_png_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "webp_image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(AchievementStatus) },
    { no: 6, name: "published_at", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "is_test", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "is_repeatable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "lang_content", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: AchievementLangContent} },
    { no: 11, name: "tasks", kind: "message", T: Task, repeated: true },
    { no: 12, name: "accomplishment_order", kind: "enum", T: proto3.getEnumType(AccomplishmentOrder) },
    { no: 13, name: "created_at", kind: "message", T: Timestamp },
    { no: 14, name: "created_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "updated_at", kind: "message", T: Timestamp },
    { no: 16, name: "updated_by", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "reset_at", kind: "message", T: Timestamp },
  ],
);

