// @generated by protoc-gen-es v1.10.0
// @generated from file services/promo/promo.proto (package pinup.players.player.promo, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.players.player.promo.GetPincoinsRequest
 */
export const GetPincoinsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.promo.GetPincoinsRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.promo.GetPincoinsResponse
 */
export const GetPincoinsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.promo.GetPincoinsResponse",
  () => [
    { no: 1, name: "privelegeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pincoinsExchange", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "privelegeLevel", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message pinup.players.player.promo.SetPincoinsRequest
 */
export const SetPincoinsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.promo.SetPincoinsRequest",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "privelegeStatus", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "pincoinsExchange", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "privelegeLevel", kind: "scalar", T: 5 /* ScalarType.INT32 */, opt: true },
  ],
);

/**
 * @generated from message pinup.players.player.promo.SetPincoinsResponse
 */
export const SetPincoinsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.promo.SetPincoinsResponse",
  [],
);

