<form
  suspense-area
  (ngSubmit)="suspense.run(send())"
  puInertSuspense
  puSuspense
  #form="ngForm"
  #suspense="puSuspense"
>
  <div mat-dialog-title>Decline payment: {{ data.withdrawalId }}</div>
  <pu-dialog-content>
    <label>Reason</label>
    <mat-radio-group [(ngModel)]="reason" name="reason">
      @for (option of REASON_OPTIONS; track option.value) {
        <mat-radio-button class="checkbox" [value]="option.value">
          {{ option.label }}
        </mat-radio-button>
      }
    </mat-radio-group>
    @if (reason() === DeclineReason.OTHER) {
      <mat-form-field class="field">
        <mat-label>Note</mat-label>
        <textarea
          [(ngModel)]="comment"
          [maxlength]="maxLength"
          [puStrictMinLength]="minLength"
          [required]="reason() === DeclineReason.OTHER || pinned()"
          cdkAutosizeMinRows="2"
          cdkTextareaAutosize
          matInput
          name="comment"
          placeholder="Type here…"
          #commentField="ngModel"
        ></textarea>
        @if (commentField | validationError; as error) {
          <mat-error>{{ error }}</mat-error>
        }
      </mat-form-field>
      <mat-checkbox [(ngModel)]="pinned" name="isPinned">Pin note</mat-checkbox>
    }
  </pu-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button class="button" matDialogClose type="button">
      Cancel
    </button>
    <button
      mat-flat-button
      class="button"
      [disabled]="reason() === DeclineReason.UNSPECIFIED || form.invalid"
      color="primary"
      type="submit"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
