<div class="search-bar pb-2 pt-2 pl-6 pr-6">
  <div class="flex justify-end align-items-center pb-2 pt-2 pl-6 pr-6">
    <mat-form-field class="search small" subscriptSizing="dynamic">
      <input
        [(ngModel)]="search"
        matInput
        placeholder="Search by IP"
        type="text"
      />
      <bo-svg
        class="icon ml-2"
        [size]="32"
        [unsafeSvgSource]="searchIcon"
        matPrefix
      />
    </mat-form-field>
  </div>
  <span class="hint label-color flex justify-end align-items-center pr-6">
    For now filtration only for displayed items
  </span>
</div>

<mat-expansion-panel class="expansion-element" expanded togglePosition="before">
  <mat-expansion-panel-header class="expansion-element-header">
    <h3>
      <span class="title-label">Active sessions</span>
      @if (activeSessionsCount$ | async; as total) {
        <span class="total-items">{{ total }}</span>
      }
    </h3>
  </mat-expansion-panel-header>
  <div class="active-sessions">
    @if (isActiveSessionsLoading$ | async) {
      <div class="flex justify-center">
        <mat-spinner [diameter]="20" />
      </div>
    }

    <table
      mat-table
      class="table-element"
      (matSortChange)="setSort(sessionType.ACTIVE, $event)"
      [dataSource]="activeSessions$"
      [hidden]="!(activeSessionsCount$ | async)"
      matSort
    >
      <ng-container [matColumnDef]="profileSessionsColumns.IP">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>IP</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.ipAddress }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.SOURCE">
        <th *matHeaderCellDef mat-header-cell>Source</th>
        <td *matCellDef="let element" mat-cell>
          <span class="uppercase">{{ element.project }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DEVICE">
        <th *matHeaderCellDef mat-header-cell>Device</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.device }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.LOCATION">
        <th *matHeaderCellDef mat-header-cell>Location</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.location }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DATE">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
        <td *matCellDef="let element" mat-cell>
          <bo-date-time [value]="element.createdAt" />
        </td>
      </ng-container>

      <tr *matHeaderRowDef="iterableColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: iterableColumns" mat-row></tr>
    </table>

    @if (shouldShowToggleBtn$ | async) {
      <button
        mat-stroked-button
        class="button show-btn mt-3"
        (click)="toggleActiveSessions()"
        data-testid="showAllActiveSessions"
        type="button"
      >
        {{ (isAllActiveSessions$ | async) ? 'Show less' : 'Show all' }}
      </button>
    }

    @if (activeSessionsEmpty$ | async) {
      <bo-empty-template [isError]="true" message="No sessions found" />
    }
  </div>
</mat-expansion-panel>

<mat-expansion-panel class="expansion-element" expanded togglePosition="before">
  <mat-expansion-panel-header class="expansion-element-header">
    <h3>
      <span class="title-label">Inactive sessions</span>
      @if (inactiveSessionsCount$ | async; as total) {
        <span class="total-items">{{ total }}</span>
      }
    </h3>
  </mat-expansion-panel-header>

  <div class="inactive-sessions">
    @if (isInactiveSessionsLoading$ | async) {
      <div class="flex justify-center">
        <mat-spinner [diameter]="20" />
      </div>
    }
    <table
      mat-table
      class="table-element"
      (matSortChange)="setSort(sessionType.INACTIVE, $event)"
      [dataSource]="inactiveSessions$"
      [hidden]="!(inactiveSessionsCount$ | async)"
      matSort
    >
      <ng-container [matColumnDef]="profileSessionsColumns.IP">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>IP</th>
        <td *matCellDef="let element" mat-cell>
          <span>{{ element.ipAddress }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.SOURCE">
        <th *matHeaderCellDef mat-header-cell>Source</th>
        <td *matCellDef="let element" mat-cell>
          <span class="uppercase">{{ element.project }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DEVICE">
        <th *matHeaderCellDef mat-header-cell>Device</th>
        <td *matCellDef="let element" mat-cell>
          <span>{{ element.device }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.LOCATION">
        <th *matHeaderCellDef mat-header-cell>Location</th>
        <td *matCellDef="let element" mat-cell>
          <span>{{ element.location }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="profileSessionsColumns.DATE">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
        <td *matCellDef="let element" mat-cell>
          <bo-date-time [value]="element.createdAt" />
        </td>
      </ng-container>

      <tr *matHeaderRowDef="iterableColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: iterableColumns" mat-row></tr>
    </table>

    @if (shouldShowMoreBtn$ | async) {
      <button
        mat-stroked-button
        class="button show-btn mt-3"
        (click)="showMoreInactiveSessions()"
        data-testid="showMoreInactiveSessions"
        type="button"
      >
        Show more
      </button>
    }
    @if (shouldShowLessBtn$ | async) {
      <button
        mat-stroked-button
        class="button show-btn mt-3"
        (click)="showFirstInactiveSessions()"
        data-testid="showMoreInactiveSessions"
        type="button"
      >
        Show first
      </button>
    }
  </div>

  @if (inactiveSessionsEmpty$ | async) {
    <bo-empty-template [isError]="true" message="No sessions found" />
  }
</mat-expansion-panel>
