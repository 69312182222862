import { Directive, effect, model } from '@angular/core';

import { DEFAULT_PAGINATION } from './pagination.const';
import { PaginationControlBase } from './pagination-control.base';
import type { Pagination } from './pagination-panel.model';

@Directive({
  selector: '[boPagination]',
  standalone: true,
})
export class PaginationPanelDirective extends PaginationControlBase {
  public readonly pagination = model<Partial<Pagination>>(DEFAULT_PAGINATION, {
    alias: 'boPagination',
  });

  constructor() {
    super();

    effect(() => {
      this.valueAccessor?.writeValue(this.pagination());
    });
  }

  protected onChange(value: Partial<Pagination>): void {
    this.pagination.update((pagination) => ({ ...pagination, ...value }));
  }
}
