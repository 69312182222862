// @generated by protoc-gen-es v1.10.0
// @generated from file services/platformintegrations/platformintegrations.proto (package pinup.players.player.platformintegrations, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message pinup.players.player.platformintegrations.GetCurrencyInfosRequest
 */
export const GetCurrencyInfosRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetCurrencyInfosRequest",
  () => [
    { no: 1, name: "playerIDs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.CurrencyInfo
 */
export const CurrencyInfo = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.CurrencyInfo",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "currencyID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.GetCurrencyInfosResponse
 */
export const GetCurrencyInfosResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetCurrencyInfosResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: CurrencyInfo, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.GetAddressesRequest
 */
export const GetAddressesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetAddressesRequest",
  () => [
    { no: 1, name: "playerIDs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.Address
 */
export const Address = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.Address",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "geoCurrent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.GetAddressesResponse
 */
export const GetAddressesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetAddressesResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: Address, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.GetAffiliatesRequest
 */
export const GetAffiliatesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetAffiliatesRequest",
  () => [
    { no: 1, name: "playerIDs", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.Affiliate
 */
export const Affiliate = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.Affiliate",
  () => [
    { no: 1, name: "playerID", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "clickID", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "partnerParams", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ],
);

/**
 * @generated from message pinup.players.player.platformintegrations.GetAffiliatesResponse
 */
export const GetAffiliatesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.players.player.platformintegrations.GetAffiliatesResponse",
  () => [
    { no: 1, name: "players", kind: "message", T: Affiliate, repeated: true },
  ],
);

