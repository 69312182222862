// @generated by protoc-gen-es v1.10.0
// @generated from file pinup/product/backoffice_operator/operator/operator_modify.proto (package pinup.product.backoffice_operator.operator, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum pinup.product.backoffice_operator.operator.OperatorUpsertStatus
 */
export const OperatorUpsertStatus = /*@__PURE__*/ proto3.makeEnum(
  "pinup.product.backoffice_operator.operator.OperatorUpsertStatus",
  [
    {no: 0, name: "UNSPECIFIED"},
    {no: 1, name: "INSERTED"},
    {no: 2, name: "UPDATED"},
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.UpsertOperatorRequest
 */
export const UpsertOperatorRequest = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.UpsertOperatorRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "sub", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "groups", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message pinup.product.backoffice_operator.operator.UpsertOperatorResponse
 */
export const UpsertOperatorResponse = /*@__PURE__*/ proto3.makeMessageType(
  "pinup.product.backoffice_operator.operator.UpsertOperatorResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(OperatorUpsertStatus) },
  ],
);

