import { inject } from '@angular/core';

import { BO_PAGINATION_VALUE_ACCESSOR, type Pagination } from '@bo/common';

export abstract class PaginationControlBase {
  protected abstract onChange(value: Partial<Pagination>): void;

  protected readonly valueAccessor = inject(BO_PAGINATION_VALUE_ACCESSOR, {
    optional: true,
    self: true,
  });

  protected constructor() {
    if (!this.valueAccessor) {
      throw new Error(
        'PaginationPanelDirective can be applied only for pagination value accessor',
      );
    }

    this.valueAccessor.registerOnChange(this.onChange.bind(this));
  }
}
