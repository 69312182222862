import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';

import type { IconSize } from '@bo/common';

// TODO use bo-icon instead risk-icon, remove risk-icon
@Component({
  selector: 'bo-icon',
  templateUrl: './icon.component.html',
  standalone: true,
  imports: [NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation -- FIXME
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  /**
   * @internal
   */
  @HostBinding('class.risk-icon') public hostClass = true;
  /**
   * Corresponds to svg file name inside /icons/svg-icons or bet folder.
   */
  @Input() public iconName: string;

  /**
   * Icon size
   */
  @Input() public size: IconSize = 's';

  /**
   * Icon stroke (color)
   */
  @Input() public stroke?: string;

  /**
   * Icon fill (color)
   */
  @Input() public fillColor?: string;

  /**
   * Icons collection
   */
  @Input() public collection: 'common' | 'flags' = 'common';
  // Temp solution. TODO: migrate fill and stroke entirely to currentColor
  @Input() public useCurrentColor = false;
  @Input() public showPlaceholder = false;
}
