import {
  ChangeDetectionStrategy,
  Component,
  inject,
  InjectionToken,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { PlayerRemoteService } from '@pinup-grpc/services/players/players_ng';

import {
  DIALOG_NOTE_COMMENT,
  DIALOG_NOTE_MAX_LENGTH,
  DIALOG_NOTE_MIN_LENGTH,
  DIALOG_NOTE_PINNED,
} from '@pu/pinup';
import {
  DialogContentComponent,
  DialogSuspenseDirective,
  InertSuspenseDirective,
  StrictMinLengthDirective,
  SuspenseAreaComponent,
  SuspenseDirective,
  SvgComponent,
  ValidationErrorPipe,
} from '@pu/sdk';

import { withdrawalIcon } from '../icons';

export interface AutoWithdrawalOnDialogData {
  readonly playerId: bigint;
}

export const AUTO_WITHDRAWAL_ON_DIALOG_DATA =
  new InjectionToken<AutoWithdrawalOnDialogData>(
    'AUTO_WITHDRAWAL_ON_DIALOG_DATA',
  );

@Component({
  selector: 'bo-auto-withdrawal-on-dialog',
  standalone: true,
  imports: [
    FormsModule,
    MatButton,
    MatCheckbox,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatError,
    MatFormField,
    MatInput,
    MatLabel,
    MatSlideToggle,
    SvgComponent,
    SuspenseDirective,
    StrictMinLengthDirective,
    ValidationErrorPipe,
    DialogSuspenseDirective,
    SuspenseAreaComponent,
    InertSuspenseDirective,
    DialogContentComponent,
  ],
  templateUrl: './auto-withdrawal-on-dialog.component.html',
  styleUrl: './auto-withdrawal-on-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoWithdrawalOnDialogComponent {
  protected readonly withdrawalIcon = withdrawalIcon;

  protected readonly minLength = inject(DIALOG_NOTE_MIN_LENGTH);
  protected readonly maxLength = inject(DIALOG_NOTE_MAX_LENGTH);
  protected readonly data = inject(AUTO_WITHDRAWAL_ON_DIALOG_DATA);
  protected readonly playerRemoteService = inject(PlayerRemoteService);

  protected on = signal(false);
  protected comment = signal(inject(DIALOG_NOTE_COMMENT));
  protected pinned = signal(inject(DIALOG_NOTE_PINNED));
}
