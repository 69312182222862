import { AccountVerifyState } from '@pinup-grpc/services/bo/players/players_pb';

import {
  WithdrawalRequestConfirmType,
  WithdrawalRequestReason,
  WithdrawalRequestStatus,
} from '@pu/grpc';
import type { License, RadioToggleOption } from '@bo/common';

import type { RiskDurationConfig } from '../withdrawals-filter.types';

export const withdrawalConfirmTypeOptions: ReadonlyMap<
  WithdrawalRequestConfirmType,
  string
> = new Map<WithdrawalRequestConfirmType, string>([
  [WithdrawalRequestConfirmType.AUTO, 'AUTO'],
  [WithdrawalRequestConfirmType.MANUAL, 'MANUAL'],
  [
    WithdrawalRequestConfirmType.MANUAL_CHANGED_AMOUNT,
    'Manual with changed withdrawal',
  ],
]);

export const withdrawalStatusOptions: ReadonlyMap<
  WithdrawalRequestStatus,
  string
> = new Map<WithdrawalRequestStatus, string>([
  [WithdrawalRequestStatus.NEW, 'NEW'],
  [WithdrawalRequestStatus.APPROVED, 'APPROVED'],
  [WithdrawalRequestStatus.CANCELED, 'CANCELED'],
  [WithdrawalRequestStatus.USER_CANCELED, 'CANCELED USER'],
  [WithdrawalRequestStatus.AW_PROCESSING, 'AW PROCESSING'],
  [WithdrawalRequestStatus.RISK_REVIEWING, 'RISK REVIEWING'],
  [WithdrawalRequestStatus.FAILED, 'FAILED'],
  [WithdrawalRequestStatus.COMPLETED, 'COMPLETED'],
  [WithdrawalRequestStatus.IN_PROGRESS, 'IN PROGRESS'],
  [WithdrawalRequestStatus.NOT_CREATED, 'NOT CREATED'],
]);

export const withdrawalRequestStatusMap: Record<
  string,
  WithdrawalRequestStatus
> = {
  [WithdrawalRequestStatus.NEW]: WithdrawalRequestStatus.NEW,
  [WithdrawalRequestStatus.APPROVED]: WithdrawalRequestStatus.APPROVED,
  [WithdrawalRequestStatus.CANCELED]: WithdrawalRequestStatus.CANCELED,
  [WithdrawalRequestStatus.USER_CANCELED]:
    WithdrawalRequestStatus.USER_CANCELED,
  [WithdrawalRequestStatus.AW_PROCESSING]:
    WithdrawalRequestStatus.AW_PROCESSING,
  [WithdrawalRequestStatus.RISK_REVIEWING]:
    WithdrawalRequestStatus.RISK_REVIEWING,
  [WithdrawalRequestStatus.FAILED]: WithdrawalRequestStatus.FAILED,
  [WithdrawalRequestStatus.COMPLETED]: WithdrawalRequestStatus.COMPLETED,
  [WithdrawalRequestStatus.IN_PROGRESS]: WithdrawalRequestStatus.IN_PROGRESS,
  [WithdrawalRequestStatus.NOT_CREATED]: WithdrawalRequestStatus.NOT_CREATED,
};

export function* generateWithdrawalRequestReasonOptions(
  license: License,
): Iterable<{ key: WithdrawalRequestReason; value: string }> {
  yield {
    key: WithdrawalRequestReason.UNSPECIFIED,
    value: 'Other',
  };
  yield {
    key: WithdrawalRequestReason.LAST_DEPOSIT_TURNOVER,
    value: 'Reason: /No turnover of last deposit',
  };
  yield {
    key: WithdrawalRequestReason.TURNOVER,
    value: 'Reason: /Turnover',
  };
  yield {
    key: WithdrawalRequestReason.LIMIT,
    value: 'Reason: /Limit',
  };
  if (license.supports('casino')) {
    yield {
      key: WithdrawalRequestReason.BET_COUNT_CASINO,
      value: 'Reason: /BetCountCasino',
    };
  }
  yield {
    key: WithdrawalRequestReason.NO_BET_SINCE_LAST_DEPOSIT,
    value: 'Reason: /NoBets Since last deposit',
  };
  yield {
    key: WithdrawalRequestReason.AUTO_OFF_ALL_IN_FIRST_DEP,
    value: 'Reason: /AWOFF:All-in first deposit',
  };
  yield {
    key: WithdrawalRequestReason.AUTO_OFF_FAST,
    value: 'Reason: /AWOFF:Fast MoneyOut',
  };
  yield {
    key: WithdrawalRequestReason.NOT_SAME_SYSTEM_NAME,
    value: 'Reason: /Limit/No Same System Name found',
  };
  if (license.supports('casino')) {
    yield {
      key: WithdrawalRequestReason.BLOCK_CASINO_STATUS,
      value: 'Reason: /BlockStatusCasino CZ',
    };
  }
  if (license.supports('sport')) {
    yield {
      key: WithdrawalRequestReason.BLOCK_SPORT_STATUS,
      value: 'Reason: /BlockStatusBet B2,B3,BZ',
    };
  }
}

export const withdrawalRiskDurationConstants = {
  ONE_HOUR_SECONDS: 3600n,
  SIX_HOUR_SECONDS: 21600n,
  TWELVE_HOUR_SECONDS: 43200n,
  ONE_DAY_SECONDS: 86400n,
} as const;

export const withdrawalRiskDurationConfig: RiskDurationConfig[] = [
  {
    value: withdrawalRiskDurationConstants.ONE_HOUR_SECONDS,
    label: '> 1H',
  },
  {
    value: withdrawalRiskDurationConstants.SIX_HOUR_SECONDS,
    label: '> 6H',
  },
  {
    value: withdrawalRiskDurationConstants.TWELVE_HOUR_SECONDS,
    label: '> 12H',
  },
  {
    value: withdrawalRiskDurationConstants.ONE_DAY_SECONDS,
    label: '> 24H',
  },
];

export const verifiesStatusOptions: RadioToggleOption<
  string,
  AccountVerifyState
>[] = [
  { name: 'True', value: AccountVerifyState.ACCOUNT_VERIFY_YES },
  { name: 'False', value: AccountVerifyState.ACCOUNT_VERIFY_NO },
];
