import type { WithdrawalRequestConfirmType } from '@pinup-grpc/pinup/stat/report/withdrawals/withdrawals_pb';
import {
  ListRequestsRequest_ReasonMultiselect,
  ListRequestsRequest_RiskDuration,
} from '@pinup-grpc/pinup/stat/report/withdrawals/withdrawals_pb';
import {
  ListWithdrawalsRequest_StringMultiselect,
  ListWithdrawalsRequest_Uint64Multiselect,
} from '@pinup-grpc/services/bo/withdrawals/withdrawals_pb';

import type { WithdrawalRequestStatus } from '@pu/grpc';
import { VerifyStatus } from '@pu/grpc';
import { enumToKey, stringToBigInt, toStringArray } from '@pu/sdk';

import { withdrawalRequestStatusMap } from './withdrawals-filter.const';

export enum WithdrawalsTab {
  MANUAL = 'manual',
  TEST = 'test',
  ALL = 'all',
}

export function toVerifyStatus(value: string): VerifyStatus {
  const key = enumToKey(VerifyStatus, Number(value));
  return VerifyStatus[key];
}

export function toUint64Multiselect(
  include: string | null | undefined,
  exclude: string | null | undefined,
): ListWithdrawalsRequest_Uint64Multiselect {
  const ids = toStringArray(include).map(stringToBigInt);
  const excludedIds = toStringArray(exclude).map(stringToBigInt);

  return new ListWithdrawalsRequest_Uint64Multiselect({ ids, excludedIds });
}

export function toRequestConfirmType(
  rawValue: string | null | undefined,
): WithdrawalRequestConfirmType[] {
  return rawValue ? [Number(rawValue)] : [];
}

export function toWithdrawalRequestStatus(
  value: string | null | undefined,
): WithdrawalRequestStatus[] {
  return value
    ? toStringArray(value).map((status) => withdrawalRequestStatusMap[status])
    : [];
}

export function toStringMultiselect(
  value: string | null | undefined,
): ListWithdrawalsRequest_StringMultiselect {
  const ids = toStringArray(value);

  return new ListWithdrawalsRequest_StringMultiselect({ ids });
}

export function toReasonMultiselect(
  value: string | null | undefined,
): ListRequestsRequest_ReasonMultiselect {
  const reasons = toStringArray(value).map(Number);

  return new ListRequestsRequest_ReasonMultiselect({ reasons });
}

export function isPlayerTest(tab: WithdrawalsTab): tab is WithdrawalsTab.TEST {
  return tab === WithdrawalsTab.TEST;
}

export function toRiskDuration(
  value: string | null | undefined,
): ListRequestsRequest_RiskDuration {
  const gte = value ? BigInt(value) : 0n;

  return new ListRequestsRequest_RiskDuration({ gte });
}
