import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  signal,
} from '@angular/core';
import { noop } from 'rxjs';

import type { OnChangeListener } from '@pu/sdk';
import { provideExisting } from '@pu/sdk';

import { GoToPaginationComponent } from './go-to-pagination/go-to-pagination.component';
import { PageSizeSelectComponent } from './page-size-select/page-size-select.component';
import { PaginationComponent } from './pagination.component';
import { DEFAULT_PAGINATION } from './pagination.const';
import type {
  Pagination,
  PaginationValueAccessor,
} from './pagination-panel.model';
import { BO_PAGINATION_VALUE_ACCESSOR } from './pagination-panel.providers';

type PaginationChangeListener = OnChangeListener<Partial<Pagination>>;

@Component({
  selector: 'bo-pagination-panel',
  templateUrl: './pagination-panel.component.html',
  styleUrls: ['./pagination-panel.component.scss'],
  standalone: true,
  imports: [
    PageSizeSelectComponent,
    PaginationComponent,
    GoToPaginationComponent,
  ],
  providers: [
    provideExisting<PaginationValueAccessor>(
      BO_PAGINATION_VALUE_ACCESSOR,
      forwardRef(() => PaginationPanelComponent),
    ),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationPanelComponent implements PaginationValueAccessor {
  /**
   * @deprecated Will be removed after full migration to PaginationPanelDirective
   */
  @Input() set totalItems(value: number) {
    this.writeValue({ totalItems: value });
  }

  /**
   * @deprecated Will be removed after full migration to PaginationPanelDirective
   */
  @Input() set totalPages(value: number) {
    this.writeValue({ totalPages: value });
  }

  private propagateChange: PaginationChangeListener = noop;

  protected readonly pagination = signal<Pagination>(DEFAULT_PAGINATION);

  public registerOnChange(fn: PaginationChangeListener): void {
    this.propagateChange = fn;
  }

  public writeValue(value: Partial<Pagination>): void {
    this.pagination.update((pagination) => ({ ...pagination, ...value }));
  }

  protected onCurrentPageChange(currentPage: number): void {
    this.propagateChange({ currentPage });
  }

  protected onPageSizeChange(pageSize: number): void {
    // Reset current page on page size change
    this.propagateChange({ pageSize, currentPage: 1 });
  }
}
