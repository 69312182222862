import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import { MatFormField, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import type { Sort } from '@angular/material/sort';
import { MatSortModule } from '@angular/material/sort';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { combineLatest, map } from 'rxjs';

import { git_time2go_tech_apis_pinup_admin_api_gw_services_players_sessions_SessionType as SessionType } from '@pu/grpc/enums';
import {
  CurrentRouteNavigationService,
  extractEnumValues,
  searchIcon,
  SvgComponent,
} from '@pu/sdk';

import { DateTimeComponent } from '../../date-time/date-time.component';
import { EmptyTemplateComponent } from '../../empty-template/empty-template.component';
import { LoadMorePaginationService } from '../../pagination/load-more-pagination/load-more-pagination.service';
import {
  DEFAULT_ACTIVE_SESSIONS_DATA_SHOW,
  ProfileSessionsColumns,
} from './profile-sessions.const';
import { ProfileSessionsProviderService } from './profile-sessions.provider';
import { ProfileSessionsService } from './profile-sessions.service';

export const DEFAULT_LIMIT = 10n;

@Component({
  selector: 'bo-profile-sessions-page',
  standalone: true,
  imports: [
    AsyncPipe,
    DateTimeComponent,
    MatCell,
    EmptyTemplateComponent,
    MatTable,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatRow,
    EmptyTemplateComponent,
    MatButton,
    MatSortModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatHeaderCellDef,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    MatFormField,
    MatInput,
    FormsModule,
    MatIcon,
    SvgComponent,
    MatPrefix,
    MatProgressSpinner,
  ],
  providers: [
    ProfileSessionsService,
    ProfileSessionsProviderService,
    CurrentRouteNavigationService,
    LoadMorePaginationService,
  ],
  templateUrl: './profile-sessions-page.component.html',
  styleUrl: './profile-sessions-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileAllSessionsPageComponent {
  public readonly profileSessionsService = inject(ProfileSessionsService);
  public readonly paginationService = inject(LoadMorePaginationService);

  protected readonly search = signal('');
  protected readonly searchIcon = searchIcon;

  protected readonly search$ = toObservable(this.search);

  public sortedActiveSessions$ =
    this.profileSessionsService.sortedActiveSessions$;
  public activeSessions$ = combineLatest([
    this.sortedActiveSessions$,
    this.search$,
  ]).pipe(
    map(([sessions, search]) =>
      sessions.filter(({ ipAddress }) => ipAddress.includes(search)),
    ),
  );
  public isAllActiveSessions$ =
    this.profileSessionsService.isAllActiveSessions$;

  public sortedInactiveSessions$ =
    this.profileSessionsService.sortedInactiveSessions$;
  public inactiveSessions$ = combineLatest([
    this.sortedInactiveSessions$,
    this.search$,
  ]).pipe(
    map(([sessions, search]) =>
      sessions.filter(({ ipAddress }) => ipAddress.includes(search)),
    ),
  );

  protected activeSessionsCount$ =
    this.profileSessionsService.activeSessionsCount$;
  protected inactiveSessionsCount$ =
    this.profileSessionsService.inactiveSessionsCount$;
  public readonly isActiveSessionsLoading$ =
    this.profileSessionsService.loadingActiveSessions$;
  public readonly isInactiveSessionsLoading$ =
    this.profileSessionsService.loadingInactiveSessions$;

  protected readonly sessionType = SessionType;
  protected readonly profileSessionsColumns = ProfileSessionsColumns;
  protected readonly iterableColumns = extractEnumValues(
    ProfileSessionsColumns,
  );

  protected shouldShowToggleBtn$ = this.activeSessionsCount$.pipe(
    map((count) => count > DEFAULT_ACTIVE_SESSIONS_DATA_SHOW.to),
  );

  protected shouldShowLessBtn$ = combineLatest([
    this.inactiveSessionsCount$,
    this.sortedInactiveSessions$,
  ]).pipe(
    map(
      ([count, sessions]) =>
        Number(count) === sessions.length && count > DEFAULT_LIMIT,
    ),
  );

  public shouldShowMoreBtn$ = combineLatest([
    this.inactiveSessionsCount$,
    this.sortedInactiveSessions$,
  ]).pipe(map(([count, sessions]) => Number(count) > sessions.length));

  public activeSessionsEmpty$ = combineLatest([
    this.activeSessionsCount$,
    this.isActiveSessionsLoading$,
  ]).pipe(map(([count, isLoading]) => !count && !isLoading));

  public inactiveSessionsEmpty$ = combineLatest([
    this.inactiveSessionsCount$,
    this.isInactiveSessionsLoading$,
  ]).pipe(map(([count, isLoading]) => !count && !isLoading));

  public toggleActiveSessions(): void {
    this.profileSessionsService.toggleActiveSession();
  }

  public showMoreInactiveSessions(): void {
    this.paginationService.loadMore();
  }

  public showFirstInactiveSessions(): void {
    this.paginationService.setDefaultPagination();
  }

  public setSort(type: SessionType, sort: Sort): void {
    this.profileSessionsService.setSort(type, sort);
  }
}
