import type { AccessGroupLicense } from './access-group';

export interface LicenseOptions {
  readonly url?: string;
}

export type LicenseFeature =
  | 'bespoke-freebet'
  | 'bespoke-sport'
  | 'betby-freebet'
  | 'betby'
  | 'betting-status'
  | 'cashback'
  | 'casino-bet'
  | 'casino-status'
  | 'casino-win'
  | 'casino'
  | 'cpf'
  | 'currency'
  | 'digitain-freebet'
  | 'dw-tax'
  | 'freebet'
  | 'freespin'
  | 'geo'
  | 'lottery'
  | 'pincoins'
  | 're-verification'
  | 'sport-bet'
  | 'sport-betby'
  | 'sport-digitain'
  | 'sport-win'
  | 'sport'
  | 'tax-calculation'
  | 'tax-information'
  | 'tv-games'
  | 'vs';

export class License {
  public static create(
    group: AccessGroupLicense,
    origin: string,
    title: string,
    host: string,
    features: Iterable<LicenseFeature>,
    brand?: string,
    { url = group }: LicenseOptions = {},
  ): License {
    return new License(
      group,
      origin,
      title,
      url,
      host,
      new Set<LicenseFeature>(features),
      brand,
    );
  }

  private constructor(
    public readonly group: AccessGroupLicense,
    public readonly title: string,
    public readonly origin: string,
    public readonly url: string,
    public readonly host: string,
    public readonly features: ReadonlySet<LicenseFeature>,
    public readonly brand: string | null = null,
  ) {}

  public supports(feature: LicenseFeature): boolean {
    return this.features.has(feature);
  }
}
