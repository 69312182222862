import type { LoadMorePagination } from '../load-more-pagination/load-more-pagination.service';
import type { Pagination } from './pagination-panel.model';

export const PAGINATION_MAX: Pagination = {
  currentPage: 1,
  pageSize: 100,
};

export const PAGINATION_START_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PAGINATION: Pagination = {
  currentPage: PAGINATION_START_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};

export const PAGINATION_LOAD_ALL_MAX: LoadMorePagination = {
  limit: 1000000n,
  offset: 0n,
};
