import { InjectionToken } from '@angular/core';
import type { BettingStatus } from '@pinup-grpc/services/bo/players/players_pb';

import type { DialogTitles } from '../base-dialog-with-note/dialog-with-note.model';

export type bettingDialogData = DialogTitles &
  Readonly<{
    playerId: bigint;
    bettingStatus: BettingStatus;
  }>;

export const BETTING_DIALOG_DATA = new InjectionToken<bettingDialogData>(
  'BETTING_DIALOG_DATA',
);
