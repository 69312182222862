import { WithdrawalRequestStatus } from '@pu/grpc';
import { failedStatusIcon, successStatusIcon } from '@pu/sdk';

export enum WithdrawalTransactionStatus {
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Process,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Success,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Failed,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  Pending,
  // eslint-disable-next-line @typescript-eslint/prefer-enum-initializers -- FIXME
  RiskReview,
}

export const WITHDRAWAL_STATUS_ICON_MAP = new Map<
  WithdrawalRequestStatus,
  string
>([
  [WithdrawalRequestStatus.APPROVED, successStatusIcon],
  [WithdrawalRequestStatus.COMPLETED, successStatusIcon],
  [WithdrawalRequestStatus.CANCELED, failedStatusIcon],
  [WithdrawalRequestStatus.USER_CANCELED, failedStatusIcon],
  [WithdrawalRequestStatus.FAILED, failedStatusIcon],
]);

export const WITHDRAWAL_REQUEST_STATUS_NAME_MAP: Record<
  WithdrawalRequestStatus,
  string
> = {
  [WithdrawalRequestStatus.NEW]: 'New',
  [WithdrawalRequestStatus.APPROVED]: 'Approved',
  [WithdrawalRequestStatus.CANCELED]: 'Declined',
  [WithdrawalRequestStatus.USER_CANCELED]: 'Declined by user',
  [WithdrawalRequestStatus.AW_PROCESSING]: 'ProcessAW',
  [WithdrawalRequestStatus.RISK_REVIEWING]: 'Risk Review',
  [WithdrawalRequestStatus.FAILED]: 'Failed',
  [WithdrawalRequestStatus.COMPLETED]: 'Completed',
  [WithdrawalRequestStatus.IN_PROGRESS]: 'In progress',
  [WithdrawalRequestStatus.NOT_CREATED]: 'Not created',
};
